import { render, staticRenderFns } from "./CaseAuditLog.vue?vue&type=template&id=66d7ddba&scoped=true&"
import script from "./CaseAuditLog.vue?vue&type=script&lang=js&"
export * from "./CaseAuditLog.vue?vue&type=script&lang=js&"
import style0 from "./CaseAuditLog.vue?vue&type=style&index=0&id=66d7ddba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66d7ddba",
  null
  
)

export default component.exports